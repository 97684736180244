import './hypnosis.css'

export function HypnosisLoading(){
    return(
        <div class="loader">
        <div class="outer"></div>
        <div class="middle"></div>
        <div class="inner"></div>
        </div>

    )
}