import { message } from 'antd';

export function LoginSuccess() {
    message.success('Logado');
  };
  
  export function LoginError() {
    message.error('O PIN introduzido está incorrecto..');
  };


  export function GenericSuccess(msg) {
    message.success(msg);
  };


  export function GenericError(msg) {
    message.error(msg);
  };

  export function SomeThingError() {
    message.error('Algo correu mal.');
  };