import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../../components/lib/settings";
import moment from "moment";
import "./occupy.css";
import { amountFormat } from "../../components/amount/format_amount";

import { Button, Col, Form, Input, InputNumber, Select, Row } from "antd";
import Inactivity from "../../components/inactivityTime/InactivityTime";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 2,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid registration!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} As horas devem estar entre ${min} e ${max}",
  },
};

function onFinish(values) { }

function clickOcupyRoom(values) {
  alert("Clicou para ocupar o quarto (x)");
}

function Ocuppy() {
  Inactivity()

  const location = useLocation();
  const navigate = useNavigate();
  const [roomID, setRoomID] = useState(location.state["roomCode"]);
  const [roomValor, setRoomValor] = useState();
  const [form] = Form.useForm();
  const [room, setRoom] = useState([]);
  const [price, setPrice] = useState([]);
  const [getData, setGetData] = useState(false);


  useEffect(() => {
    baseurl
      .get(`v1/list_occupy_rooms_by_code/${roomID}`)
      .then((response) => {
        setRoom(response.data);
      })
      .catch((err) => { });
    baseurl
      .get("v1/get_all_room_price")
      .then((response) => {
        setPrice(response.data);
      })
      .catch((err) => { });
    setGetData(false);
  }, [getData]);

  const closeRoom = (values) => {
    var today = new Date();
    if (values.time == "Noite") {
      today.setHours(today.getHours() + parseInt("15"));
    } else {
      today.setHours(today.getHours() + parseInt(values.time));
    }
    const data = {
      code: roomID,
      registration: values.registration,
      color: values.color,
      finalPrice: roomValor,
      time: moment(today).format("YYYY-MM-DD HH:mm:ss"),
      is_occupy: true,
      status: "Ocupado",
      aditional_time: false,
    };

    navigate("/rooms/payment/methods", {
      state: {
        data: data,
        url: 'v1/close_occupy_rooms_by_code',
      },
    });
  };

  const handleSelect = (value) => {
    price.map((item, key) => {
      if (value == item.time) {
        setRoomValor(item.price);
      }
    });
  };

  return (
    <div>
      <br />
      <br />
      <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
        <Col xs={21} xl={18}>
          <Form
            form={form}
            id={"roomID"}
            name="basic"
            layout="vertical"
            onFinish={closeRoom}
            validateMessages={validateMessages}
          >
            <Form.Item
              style={{ float: "right", fontSize: 18 }}>
              <div className="room_price">{isNaN(roomValor) && <>{amountFormat(parseInt(0))}</> || <>{amountFormat(parseInt(roomValor))}</>}</div>
            </Form.Item>
            <Form.Item
              name="time"

            >
              <Select
                placeholder="Tempo"
                style={{
                  width: 120,
                }}
                onChange={handleSelect}
              >
                {price.map((item, key) => {
                  return <Option value={item.time}>{item.time}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="registration"

              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input autoComplete="off" placeholder="Referência" />
            </Form.Item>
            <Form.Item
              name="color"

              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input autoComplete="off" placeholder="Cor" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Ocupar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Ocuppy;
