import React from 'react'
import {
    Typography,
    Form,
    Input,
    Col,
    Row,
    Button,
    Select,
    InputNumber,
    Divider
} from "antd"

const { Title } = Typography
const { Option } = Select;


const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid registration!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} As horas devem estar entre ${min} e ${max}",
    },
};

function Deposits() {
    const [form] = Form.useForm();

    return (
        <div>
            <br />
            <br />
            <Typography>
                <Title align="center" level={5}>
                    Depósito
                </Title>
            </Typography>
            <br />
            <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
                <Col xs={21} xl={18}>
                    <Form
                        form={form}
                        // id={"roomID"}
                        name="basic"
                        layout="vertical"
                        // onFinish={closeRoom}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            style={{ float: "right", fontSize: 18 }}>
                            <Select
                                placeholder="Manager"
                                style={{
                                    width: 120,
                                }}
                            // onChange={handleSelect}
                            >
                                {/* {price.map((item, key) => {
                                    return <Option value={item.time}>{item.time}</Option>;
                                    })} */}
                            </Select>

                        </Form.Item>

                        <Form.Item
                            name="time"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                        >
                            <InputNumber placeholder='valor a depositar' size="medium" min={1} max={100000} />
                        </Form.Item>
                        
                        <Divider orientation="left">Despesas adicionais</Divider>

                        <Form.Item
                            name="description"
                            // label="Matricula"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Descrição" />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            // label="Cor"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber autoComplete="off" placeholder="Valor" />
                        </Form.Item>
                        {/* <><p>VALOR {roomValor}</p>  </> */}
                        
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Depositar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default Deposits
