import React, { useEffect, useState } from 'react'
import "./employeeBalance.css"
import {
    UserOutlined,
    SearchOutlined,
    InboxOutlined,
    DollarOutlined,
    BankOutlined
} from '@ant-design/icons';
import {
    Avatar,
    Divider,
    List,
    Skeleton,
    Typography,
    Col,
    Row,
    Button,
    Tooltip,
} from "antd"
import { baseurl } from '../../components/lib/settings';
import InfiniteScroll from 'react-infinite-scroll-component';
import donation from "../../components/assets/images/svg/donation.png"
import MakeDeposit from '../../components/Modal/MakeDeposit';
import Inactivity from '../../components/inactivityTime/InactivityTime';

const { Title } = Typography;

function EmployeeBalance() {
    Inactivity()

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [usersBalance, setUsersBalance] = useState([])
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDepositModal, setIsDepositModal] = useState(false)
    const [depositVisiblie, setDepositVisible] = useState(false);
    const [getdata, setGetData] = useState(false);
    const [roomAmount, setRoomAmount] = useState(false);


    function loadMoreData() {
        if (isLoading) {
            return;
        }
    }

    function callDepositModal(item) {
        setRoomAmount(item);
        setIsDepositModal(true);
        setDepositVisible(true);
    }

    useEffect(() => {
        setIsLoading(true)
        baseurl.get("v1/get_all_users_balance/").then((resp) => {
            setUsersBalance(resp.data);
            resp.data.map((value) => setIsAdmin(value.attendant.administrator))
            setIsLoading(false)
        });

    }, []);

    return (
        <>
            <br /><br />
            <Typography>
                <Title align="center" level={5}>Saldo dos Atendentes</Title>
            </Typography>
            <br />
            <Row
                type="flex"
                style={{ alignItems: 'center' }}
                justify="center"
                block
            >
                <Col xs={21} xl={18}>
                    <div
                        id="scrollableDiv"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={data.length}
                            next={loadMoreData}
                            hasMore={data.length > 100}
                            loader={
                                <Skeleton
                                    avatar
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            <List>
                                {usersBalance.map((item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={item.is_present && <><Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /></> || <><Avatar icon={<UserOutlined />} /></>}
                                            title={<a href="#"> {item.user.user_name}  </a>}
                                            description={item.user.email}
                                        />
                                        <div>
                                            {item.user.administrator && <> <Tooltip title="Depositar"><Button shape="circle" onClick={() => callDepositModal(item.balance)} > <img src={donation} /></Button>
                                            </Tooltip> &nbsp;</> || <></>}  {item.balance}</div>
                                    </List.Item>
                                ))}
                            </List>
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
            {isDepositModal && (
                <MakeDeposit
                    visible={depositVisiblie}
                    setVisible={setDepositVisible}
                    roomAmount={roomAmount}
                    sethandleData={setGetData}
                />
            )}
        </>
    )
}

export default EmployeeBalance
