import React, { useState } from "react";
import { InputNumber, Typography } from "antd";
import { Input, Row, Button, Form, Col, Radio, Space, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { baseurl } from "../../components/lib/settings";
import { HypnosisLoading } from "../../components/loading/hypnosis";
import {
  GenericSuccess,
  LoginError,
  LoginSuccess,
  SomeThingError,
} from "../../components/message/generic_message";

import "../../App.css";
import "./payment.css";
import Inactivity from "../../components/inactivityTime/InactivityTime";
const { Title, Text } = Typography;

function Payment() {
  Inactivity()

  const location = useLocation();
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [statusmsg, setStatusMsg] = useState("Processando...");
  const [blur, setBlur] = useState("main-form");

  const onChange = (e) => {
    // console.log("clicou em:", e.target.value)
    setValue(e.target.value);
  };

  

  function savePostInfo() {
    setIsLoading(true)
    baseurl.post(`${location.state.url}`, location.state.data).then((resp) => {


      if (resp.status == 201 || resp.status == 200) {
        setIsLoading(false)
        message.success(
          `Quarto ocupado com sucesso.`
        );
        navigate("/rooms/main");
      }
    });
  }

  async function saveInfoPaymentandOccupyRoom() {
    setStatusMsg("Disponibilizando quarto...");
    const data = {
      code: location.state.roomID,
      registration: location.state.formValue.registration,
      color: location.state.formValue.color,
      finalPrice: location.state.finalPrice,
      time: location.state.time,
      is_occupy: true,
      status: "Ocupado",
      aditional_time: false,
    };


    await baseurl.post(`v1/close_occupy_rooms_by_code`, data).then((resp) => {

      if (resp.status == 201 || resp.status == 200) {
        setBlur("main-form-blur");
        setSuccess(true);
        alert("Quarto ocupado com sucesso.");
        navigate("/rooms/main");
        setIsLoading(false);
      } else {
        if (resp.status == 400 || resp.status == 408) {
          alert("Falha ao ocupar o quarto.");
        } else {
          if (resp.status == 401) {
            alert("Introduziu um PIN errado");
          } else {
            alert("Algo correu mal NO POST DO GUESTHOUSE");
          }
        }
      }
    });
  }

  return (
    <div>
      <br />
      <br />
      <br />
      {error && (
        <>
          <SomeThingError />
        </>
      )}
      {success && (
        <>
          {" "}
          <GenericSuccess msg={" Quarto ocupado com sucesso."} />{" "}
        </>
      )}
      
      {isLoading && (
        <>
          <div className="overlay" />
          <div className="content">
            <HypnosisLoading />
            <div className="center-notification">
              <h1 className="main_messages">{statusmsg}</h1>
            </div>
          </div>
        </>
      )}
      <div className={blur}>
        <Typography>
          <Title level={4}>
            <div className="geraltitleficha">
              <div className="">Método de Pagamento</div>
            </div>
          </Title>
        </Typography>
        <br />
        <Row
          type="flex"
          // style={{ alignItems: 'left' }}
          justify="center"
          block
        >
          <Col cs={21} xl={18}>
            <Form
              loading={isLoading}
              form={form}
              layout="vertical"
              // onFinish={SendPayment}
              onFinish={savePostInfo}
            >
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>Cash</Radio>
                  <Radio value={2}>Conta móvel</Radio>
                  <Radio value={3}>POS</Radio>
                  <Radio value={5}>
                    M-PESA
                    {value === 5 ? (
                      <Form.Item
                        name="number"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber
                          prefix="+258"
                          style={{
                            width: 180,
                            marginLeft: 10,
                          }}
                        />
                      </Form.Item>
                    ) : null}
                  </Radio>
                  <Radio value={6}>E-MOLA</Radio>
                  <Radio value={4}>Outro</Radio>
                </Space>
              </Radio.Group>
              <br />
              <br />
              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  disabled={isLoading} 
                  block
                >
                  Pagar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Payment;
