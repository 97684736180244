import React, { useEffect } from 'react'
import {
    Typography,
    Row,
    Col,
    Divider,
    Button,
    Form,
    Spin
} from 'antd'
import "./fine.css"
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import { baseurl } from '../../components/lib/settings';
import { LoadingOutlined } from "@ant-design/icons";
import Inactivity from '../../components/inactivityTime/InactivityTime';

const { Title } = Typography;

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid registration!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} As horas devem estar entre ${min} e ${max}",
    },
};

function Fine() {
    Inactivity()

    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm();
    const [valueToPay, setValueToPay] = useState()
    const [ocupyTimeControl, setOcupyTimeControl] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [price, setPrice] = useState([])
    const timeLimit = new Date() - new Date(location.state.room.updated_at);
    const ocupyTime = new Date(location.state.room.updated_at) - new Date(location.state.room.time);
    console.log("time", timeLimit)
    console.log("Time check", new Date() - new Date(location.state.room.updated_at))
    const loadIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
    useEffect(() => {
        if(Math.floor((timeLimit / 1000 / 60 / 60) % 24) == 0){
            setOcupyTimeControl(true)
        }
    },[])
    

    var dict = location.state.room
    console.log("dict do script", dict)
    function openRoomAfterFine() {
        const data = {
            code: dict.room.code,
            is_occupy: false,
            time: null,
            status: "Limpeza",
            fineAmount: valueToPay,
            aditional_time: true,
        }
        navigate("/rooms/payment/methods", {
            state: {
                data: data,
                url: 'v1/Open_Room_by_code',
            },
        });
    }

    const getValueToPay = () => {
        console.log("- _ - 1")
        const minut = Math.floor((timeLimit / 1000 / 60) % 60)
        console.log("- _ -")
        const hour = Math.floor((timeLimit / (1000 * 60 * 60)) % 24)
        const time = {
            hour:hour,
            minutes:minut
        }
        getPriceByTime(time)
        // if(hour > 2 || hour === 2){
        //     getPriceByTime("2")
        // }else if(hour === 1 && minut < 20){
        //     getPriceByTime(false)

        // }else if(hour === 1 && minut > 20){
        //     getPriceByTime("1")

        // }else if(minut > 20 || minut === 20){
        //     getPriceByTime(false)

        // }       
    }
        


    function getPriceByTime(data){
        baseurl
        .post(`v1/get_time_price_for_room/`, data)
        .then((response) => {
            setValueToPay(response.data.finalPrice);
            setIsLoading(false);
        })
        .catch((err) => { });
        
        
    }

    return (
        <div>
            <br /><br />
            {getValueToPay()}
            <br />
            <Row
                type="flex"
                style={{ alignItems: 'center' }}
                justify="center"
                block
            >
                <Col xs={21} xl={18}>
                    <Form
                        form={form}
                        id={"roomID"}
                        name="basic"
                        layout="vertical"
                        onFinish={openRoomAfterFine}
                        validateMessages={validateMessages}
                    >
                        <Form.Item>
                            <div className='general'>
                                <Typography>
                                    <Title align="center" level={5}>Multa por tempo excedido.</Title>
                                </Typography><br />
                                <div className='general_div' >
                                    <div className='atribute'>Número do quarto</div>
                                    <div className='atribute'><b>{location.state.room.room.code}</b></div>
                                </div >
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Hora de entrada</div>
                                    <div className='atribute'><b>{moment(new Date(location.state.room.updated_at)).format("HH:mm:ss")}</b></div>
                                </div>
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Tempo de ocupação</div>
                                    <div className='atribute'><b>{Math.floor((ocupyTime / 1000 / 60 / 60) % 24)} H </b></div>
                                </div>

                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Tempo excedido</div>
                                    <div className='atribute'><b>{ocupyTimeControl && <>{Math.floor((timeLimit / 1000 / 60) % 60)} min</> || <>{Math.floor((timeLimit / 1000 / 60 / 60) % 24)} H</>}</b></div>
                                </div>
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'><b>Total a pagar</b></div>
                                    <div className='atribute'><b>{isLoading && <Spin indicator={loadIcon} /> || valueToPay}</b></div>
                                </div>
                            </div>
                        </Form.Item>
                        <br />
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Pagar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default Fine;
