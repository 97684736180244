import React from 'react';
import './test.css'
function Customers(props) {
  return (
    <table>
      <thead>
        <tr>
          <th scope="col">Funcionário</th>
          <th scope="col">Q/OC</th>
          <th scope="col">T/Acumulado</th>
          <th scope="col">N/Vendas</th>
          <th scope="col">G. de Vendas</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item) => {
          return (
            <>
              <tr>
                <td data-label="Funcionário">{item.employee}</td>
                <td data-label="Q/OC">{item.ocupation}</td>
                <td data-label="T/Acumulado">{item.billing}</td>
                <td data-label="N/Vendas">{item.sales}</td>
                <td data-label="G. de Vendas">Gráfico</td>
              </tr>
            </>
          )
        })}

      </tbody>
    </table>
  );
};

export default Customers;