import React from "react";
import "./timer.css";

export default function Timer(props) {
  return (
    <div className="digitsw">
      <h1>
        {" "}
        {("0" + Math.floor((props.time / (1000 * 60 * 60)) % 24)).slice(-2)}:
        {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
        {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
      </h1>
    </div>
    // <div>
    //   <span className="digits">
    //   - {("0" + Math.floor((props.time / (1000 * 60 * 60)) % 24)).slice(-2)}:
    // </span>
    //   <span className="digits">
    //     {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
    // </span>
    // <span className="digits">
    //   {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
    // </span>
    // {/* <span className="digits mili-sec">
    //   {("0" + ((props.time / 10) % 100)).slice(-2)}
    // </span> */}
    // </div>
  );
}
