import React from 'react'
import Card from '../../components/Card/Cards'

function Room() {
    return (
        <div>
            <Card />
        </div>
    )
}

export default Room
