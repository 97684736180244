import React, { useEffect, useState } from "react";
import NotificationIcon from "../assets/images/svg/notificationIcon.png";
import { Avatar, List, message, Modal, Badge, Dropdown,Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import "../Card/card.css";
import declain from "../../components/assets/images/png/close.png";
import done from "../../components/assets/images/png/checked.png";
import { baseurl } from "../lib/settings";
import { LoadingOutlined } from "@ant-design/icons";


const fakeDataUrl =
  "https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo";
const ContainerHeight = 200;

const notyIconStyle = {
  position: "relative",
  display: "inline",
};
const notyNumStyle = {
  position: "absolute",
  right: "0",
  backgroundColor: "rgb(255, 36, 0)",
  fontSize: "11px",
  color: "white",
  display: "inline",
  padding: "3px 5px",
  borderRadius: "20px",
  width: "20px",
  textAlign: "center",
};

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
  ];
  
const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
  ];
  const data = [
    {
      title: "Sem Notificacoes",
      description: <>{clockicon} </>,
    
      // avatar: avtar,
    },
    
    ];
    
    const menu = (
      
    <List
      min-width="100%"
      className="header-notifications-dropdown "
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar shape="square" src={item.avatar} />}
            title={item.title}
            description={item.description}
          />
        </List.Item>
      )}
    />
    );
    

export default function Noty({ count, updateData }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upData, setUpData] = useState(false);
  const [pendingUsers, setPendingUsers] = useState([]);
  const loadIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setUpData(true)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function submitResponse (deposit, status) {
    setLoading(true)
    
    const data = {
      status:status,
      debit_owner: deposit.created_by.id,
      amount_to_deposit: deposit.total_deposit,
      deposit_ref: deposit.code,
    };
    baseurl.post("/v1/deposit_confirmation", data).then((resp)=>{
      message.success(
        `${resp.data}.`
      );
      setLoading(false)
      setUpData(true)
      updateData(true)
    }).catch((er) => {
      message.error(
        `${er.response}.`
      );
    })
    console.log("Dados a enviar", data)
  };


  useEffect(() => {
    baseurl
      .get("/v1/deposit_confirmation")
      .then((response) => {
        setPendingUsers(response.data);
      })
      .catch((error) => {
        console.log("algo correu mal", error);
      });
      setUpData(false)
  }, [upData]);

  const appendData = () => {
    fetch(fakeDataUrl)
      .then((res) => res.json())
      .then((body) => {
        setData(data.concat(body.results));
      });
  };

  const onScroll = (e) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      appendData();
    }
  };

  return (
    <div>
      <div style={notyIconStyle}>
        <div className="whatsapp-link">
          <a onClick={showModal}>
            {count > 0 ? <div style={notyNumStyle}>{count}</div> : null}
            <img src={NotificationIcon} alt="fireSpot" />
          </a>
        </div>
        
        
        <Modal
          title={<>Notificações &nbsp; {loading && <Spin indicator={loadIcon} /> }</> }
          visible={isModalVisible}
          // style={{ top: 250, left: 390 }}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <List>
            <VirtualList
              data={pendingUsers}
              height={ContainerHeight}
              itemHeight={50}
              itemKey="email"
              onScroll={onScroll}
            >
              {(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    title={<a href="#">{item.created_by.user_name}</a>}
                    description={
                      " Depositou " +
                      item.total_deposit +
                      " MZN na sua conta, confirma?"
                    }
                    
                  />
                  
                  <div className="notify-img" onClick={(e) => submitResponse(item, 'Denied')}>
                    <img src={declain} />
                  </div>

                  <div className="notify-img2" onClick={(e) => submitResponse(item, 'Accept')}>
                    <img src={done} />
                  </div>
                  
                 
                </List.Item>
              )}
            </VirtualList>
          </List>
        </Modal>
      </div>
    </div>
  );
}
