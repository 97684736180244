import React from "react";
import {
  Form,
  InputNumber,
  Divider,
  Button,
  Input,
  Row,
  Col,
  Typography,
} from "antd";
import "./clientEvaluation.css";
import { ReactComponent as Aceitavell } from "../../components/assets/images/evaluation/Aceitavel.svg";
import { ReactComponent as Bomm } from "../../components/assets/images/evaluation/Bomm.svg";
import { ReactComponent as Mauu } from "../../components/assets/images/evaluation/Mau.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../../components/lib/settings";

const { Title } = Typography;

function ClientEvaluation() {
  const [rooms, setRooms] = useState("");
  //Gestao dos emojis visibilidade
  const [hig, setHig] = useState("");
  const [atend, setAtend] = useState("");

  //Post para o back
  const [ip, setIP] = useState("");
  const [provincia, setProvincia] = useState("");
  const [cidade, setCidade] = useState("");
  const [pais, setPais] = useState("");
  const [operadora, setOperadora] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [comentario, setComentario] = useState("");
  const [quartosavaliacao, setQuartosAvaliacao] = useState("");
  //Cores Higiene
  const [color1, setColor1] = useState("#F0F0F0");
  const [color2, setColor2] = useState("#F0F0F0");
  const [color3, setColor3] = useState("#F0F0F0");
  //Cores Atendimento
  const [color4, setColor4] = useState("#F0F0F0");
  const [color5, setColor5] = useState("#F0F0F0");
  const [color6, setColor6] = useState("#F0F0F0");

  useEffect(() => {
    localStorage.removeItem("token");
    getData();
    getQuartos();
    sessionStorage.removeItem("page");
  }, []);

  const { TextArea } = Input;
  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {};

  function doEvaluation() {
    var data = {
      comment: comentario,
      attendance_classification: atend,
      hygiene_classification: hig,
      room: rooms,
      ip: ip,
      provincia: provincia,
      cidade: cidade,
      pais: pais,
      operadora: operadora,
      latitude: latitude,
      longitude: longitude,
    };

    baseurl.post("v1/roomclass", data).then((respo) => {
      if (respo.status === 201 || respo.status === 200) {
        window.location.reload();
        alert("Avaliação submetida com sucesso.");
      } else {
        alert("algo correu");
      }
    });
  }

  const onChange = (values) => {
    setRooms(values);
  };

  const getData = async () => {
    const res = await axios.get("https://api64.ipify.org?format=json");
    const response = await axios.get(
      "https://ipapi.co/" + res.data.ip + "/json/"
    );
    setIP(response.data.ip);
    setProvincia(response.data.city);
    setCidade(response.data.region);
    setPais(response.data.country_name);
    setOperadora(response.data.org);
    setLatitude(response.data.latitude);
    setLongitude(response.data.longitude);
  };

  const getQuartos = async () => {
    baseurl
      .get("v1/list_all_rooms_for_classifications")
      .then((response) => {
        setQuartosAvaliacao(response.data);
      })
      .catch((err) => {});
  };

  const handleClickHigiene = (eventhigiene) => {
    if (eventhigiene === "1") {
      setColor1("#27AEE9");
      setColor2("#F0F0F0");
      setColor3("#F0F0F0");
    }
    if (eventhigiene === "2") {
      setColor1("#F0F0F0");
      setColor2("#27AEE9");
      setColor3("#F0F0F0");
    }
    if (eventhigiene === "3") {
      setColor1("#F0F0F0");
      setColor2("#F0F0F0");
      setColor3("#27AEE9");
    }

    setAtend(eventhigiene);
  };

  const handleClickAtendimento = (eventAtendimento) => {
    if (eventAtendimento === "1") {
      setColor4("#27AEE9");
      setColor5("#F0F0F0");
      setColor6("#F0F0F0");
    }
    if (eventAtendimento === "2") {
      setColor4("#F0F0F0");
      setColor5("#27AEE9");
      setColor6("#F0F0F0");
    }
    if (eventAtendimento === "3") {
      setColor4("#F0F0F0");
      setColor5("#F0F0F0");
      setColor6("#27AEE9");
    }

    setHig(eventAtendimento);
  };

  return (
    <div>
      <br />
      <Row type="flex" style={{ alignItems: "center" }} justify="center">
        <Col xs={21} xl={18}>
          <Typography>
            <Title level={5}>
              <div className="geraltitleficha">
                <div className="name">Ficha de Avaliação</div>
                <div className="quarto">
                  <InputNumber
                    placeholder="Quarto"
                    min={1}
                    max={100}
                    onChange={onChange}
                    orientation="right"
                  />
                </div>
              </div>
            </Title>
          </Typography>
        </Col>
        <Col xs={21} xl={18}>
          <Form
            layout="vertical"
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="centrar">
              <Divider style={{ color: "#1890FF" }} orientation="left">
                Higiene
              </Divider>
              <p className="IP"> Mau Aceitável Bom</p>
              <div className="geralt">
                <div className="imagens">
                  <Mauu
                    fill={color1}
                    onClick={(e) => handleClickHigiene("1")}
                  />
                </div>
                <div className="imagens">
                  <Aceitavell
                    fill={color2}
                    onClick={(e) => handleClickHigiene("2")}
                  />
                </div>
                <div className="imagens">
                  <Bomm
                    fill={color3}
                    onClick={(e) => handleClickHigiene("3")}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="centrar">
              <Divider style={{ color: "#1890FF" }} orientation="left">
                Atendimento
              </Divider>
              <p className="IP"> Mau Aceitável Bom</p>
              <div className="geralt">
                <div className="imagens">
                  <Mauu
                    fill={color4}
                    onClick={(e) => handleClickAtendimento("1")}
                  />
                </div>
                <div className="imagens">
                  <Aceitavell
                    fill={color5}
                    onClick={(e) => handleClickAtendimento("2")}
                  />
                </div>
                <div className="imagens">
                  <Bomm
                    fill={color6}
                    onClick={(e) => handleClickAtendimento("3")}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="centrar">
              <TextArea
                rows={4}
                placeholder="Deixe aqui a sua sugestão"
                style={{ width: 500, height: 60 }}
                value={comentario}
                onChange={(e) => setComentario(e.target.value)}
              />
            </div>
            <br />
            <Button onClick={doEvaluation} type="primary" block>
              Submeter
            </Button>
          </Form>
          <br />
          <br />
          <br />
        </Col>
      </Row>
      <br />
    </div>
  );
}

export default ClientEvaluation;
