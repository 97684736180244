import React, { useEffect, useState } from "react";
import { Liquid } from "@ant-design/plots";

export default function LiquidPlot() {

    const config = {
        percent: 0.25,
        shape: 'rect',
        outline: {
            border: 2,
            distance: 4,
        },
        wave: {
            length: 128,
        },
    };
    return <>
        <br /><br />
        <Liquid {...config} />
    </>
}
