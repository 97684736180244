import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input, InputNumber } from "antd";


function DepositDynamicField(props) {
    return (
        <Form.List name="fields">
            {(fields, { add, remove }) => {
                return (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Divider orientation="left" plain>Despesa {index + 1}</Divider>
                                <Form.Item
                                    name={[index, "description"]}
                                    rules={[{ required: true }]}
                                    style={{
                                        alignItems: "center",
                                        fontSize: 18
                                    }}
                                >
                                    <Input placeholder="Descrição" />
                                </Form.Item>
                                <Form.Item
                                    name={[index, "amount"]}
                                    rules={[{ required: true }]}
                                    style={{
                                        alignItems: "center",
                                        fontSize: 18,
                                        width: "100%"
                                    }}
                                >
                                    <InputNumber placeholder="Valor" block />
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        alignItems: "center",
                                        fontSize: 18,

                                    }}
                                >
                                    {fields.length > 0 ? (
                                        <Button
                                            type="danger"
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            icon={<MinusCircleOutlined />}
                                        >
                                            Remover
                                        </Button>
                                    ) : null}
                                </Form.Item>
                            </div>
                        ))}
                        <Divider />
                        <Form.Item
                            style={{
                                alignItems: "center",
                                fontSize: 18
                            }}
                        >
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "100%" }}
                            >
                                <PlusOutlined /> Criar despesa adicional
                            </Button>
                        </Form.Item>
                    </>
                );
            }}
        </Form.List>
    );
}

export default DepositDynamicField;
