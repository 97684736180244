import React from "react";
import { Avatar, List, Select, Button } from "antd";
import "./Restaurant.css";
import { Table } from "antd";
// import image from "../../../src/components/assets/sales/feijoada.jpg";
import image from "../../../src/components/assets/images/testimg/feijoada.jpg";
import frango from "../../../src/components/assets/images/testimg/frango-assado.jpg";
import { Typography } from "antd";
import Inactivity from "../../components/inactivityTime/InactivityTime";

const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const onChange = (value) => {
  console.log(`selected ${value}`);
};

const onSearch = (value) => {
  console.log("search:", value);
};

const { Title } = Typography;

function Restaurant() {
  Inactivity()

  return (
    <div>
      <br />

      <br />
      <div className="vendase">Vendas</div>

      {/* <div className="geralvendas">
        <div class="box">
          <div className="esquesrdae">
            <section class="cardse">
              <div class="carde">
                <div class="card__image-containere">
                  <img src={image} />
                </div>
                <div class="card__contente">
                  <p class="card__titlee text--mediume">
                    Feijoada a Zambeziana
                  </p>
                  <div class="card__infoe">
                    <p class="card__pricee text--mediume">Adicionar</p>
                  </div>
                </div>
              </div>
              <div class="carde">
                <div class="card__image-containere">
                  <img src={image} />
                </div>
                <div class="card__contente">
                  <p class="card__titlee text--mediume">Feijoada de Maputo</p>
                  <div class="card__infoe">
                    <p class="card__pricee text--mediume">Adicionar</p>
                  </div>
                </div>
              </div>
              <div class="carde">
                <div class="card__image-containere">
                  <img src={image} />
                </div>
                <div class="card__contente">
                  <p class="card__titlee text--mediume">Feijoada de Maputo</p>
                  <div class="card__infoe">
                    <p class="card__pricee text--mediume">Adicionar</p>
                  </div>
                </div>
              </div>
              <div class="carde">
                <div class="card__image-containere">
                  <img src={image} />
                </div>
                <div class="card__contente">
                  <p class="card__titlee text--mediume">Feijoada de Maputo</p>
                  <div class="card__infoe">
                    <p class="card__pricee text--mediume">Adicionar</p>
                  </div>
                </div>
              </div>
              <div class="carde">
                <div class="card__image-containere">
                  <img src={image} />
                </div>
                <div class="card__contente">
                  <p class="card__titlee text--mediume">Feijoada de Beirra</p>
                  <div class="card__infoe">
                    <p class="card__pricee text--mediume">Adicionar</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="direita">
          <h3>Resumo pedido</h3>
          <div className="producto_quantidade">
            <div className="prod_quanty">
              <div className="producto">
                <p>
                  <b>Producto:</b> Feijoada de Beirra
                </p>
              </div>
              <div className="quantidade">
                <Select
                  showSearch
                  placeholder="Selecione a quantidade"
                  optionFilterProp="children"
                  style={{
                    width: 190,
                  }}
                  onChange={onChange}
                  onSearch={onSearch}
                >
                  <Option value="jack">1</Option>
                  <Option value="lucy">2</Option>
                  <Option value="tom">3</Option>
                  <Option value="tom">4</Option>
                  <Option value="tom">5</Option>
                </Select>
              </div>
            </div>
            <div className="subtotal">
              <p>
                <b>Subtotal:</b> 2000 MT
              </p>
            </div>
          </div>
          <div className="producto_quantidade">
            <div className="prod_quanty">
              <div className="producto">
                <p>
                  <b>Producto:</b> Feijoada de Beirra
                </p>
              </div>
              <div className="quantidade">
                <Select
                  showSearch
                  placeholder="Selecione a quantidade"
                  optionFilterProp="children"
                  style={{
                    width: 190,
                  }}
                  onChange={onChange}
                  onSearch={onSearch}
                >
                  <Option value="jack">1</Option>
                  <Option value="lucy">2</Option>
                  <Option value="tom">3</Option>
                  <Option value="tom">4</Option>
                  <Option value="tom">5</Option>
                </Select>
              </div>
            </div>
            <div className="subtotal">
              <p>
                <b>Subtotal:</b> 2000 MT
              </p>
            </div>
          </div>
          <div className="inform_quarto">
            <div className="texto">
              <p>
                <b>Selecione o quarto:</b>
              </p>
            </div>
            <div className="inform-quartoo">
              <Select
                showSearch
                placeholder="Selecione o quarto"
                optionFilterProp="children"
                style={{
                  width: 190,
                }}
                onChange={onChange}
                onSearch={onSearch}
              >
                <Option value="jack">1</Option>
                <Option value="lucy">2</Option>
                <Option value="tom">3</Option>
                <Option value="tom">4</Option>
              </Select>
            </div>
          </div>
          <div>
            <p className="total_to_pay">
              <b>Total:</b> 4000 MT
            </p>
          </div>
          <div className="send_order">
            <Button
              style={{
                width: 100,
              }}
              type="primary"
            >
              Enviar
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Restaurant;
