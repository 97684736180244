import React from "react";
import { Row, Col, Button } from "antd"
import 'antd/dist/antd.css';
import { useNavigate } from "react-router-dom";
import quarto from "../../components/assets/images/rooms/rooms.png"
import manutencao from "../../components/assets/images/rooms/tool.png"
import Inactivity from "../../components/inactivityTime/InactivityTime";


function Options() {
    Inactivity()

    function cleaning() {
        navigate("/rooms/cleaning")
    }

    function maintenance() {
        navigate("/rooms/maintenance")
    }

    const navigate = useNavigate()


    return (
        <div>

            <main>
                <section class="cards">
                    <div class="card">
                        <div class="card__image-container" onClick={cleaning}>
                            <img src={quarto} />
                        </div>
                        <div class="card__content">
                            <p class="card__title text--medium">Limpeza</p>
                            <div class="card__info">
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card__image-container" onClick={maintenance}>
                            <img src={manutencao} />
                        </div>
                        <div class="card__content">
                            <p class="card__title text--medium">Manutencao</p>

                        </div>
                    </div>
                </section>


            </main>


            {/* 
                <Button onClick={cleaning} type="primary">Limpeza</Button>
                <Button onClick={maintenance} type="primary">Manuntencao</Button> */}
        </div>
    )
}

export default Options