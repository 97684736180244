import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';
import { Col, Row } from 'antd';

export default function BarChart(props) {
  const data = props.data.map((item) =>(
    {
      line: `${item.room}`,
      column: item.ocupation,
    }
  )) 

  const config = {
    data,
    xField: 'line',
    yField: 'column',
    label: {
      position: 'middle',
      style: {
        fill: '#red',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      line: {
        alias: '*',
      },
      column: {
        alias: '0',
      },
    },
  };
  return <>
    <br /><br />
    <Row
      type="flex"
      style={{ alignItems: 'center' }}
      justify="center"
      block
    >
      <Col
        xs={21}
        xl={18}
      >
        <Column {...config} />
      </Col>
    </Row>
  </>;
};
