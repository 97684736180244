import React, { useEffect, useState } from 'react';
import { Button, Modal, Col, Row } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { baseurl } from '../lib/settings';





const RoomOptions = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const { visible, setVisible, onCancel, code, valor, status, room, sethandleData } = props;
    const [isModalVisible, setIsModalVisible] = useState(visible);
    const [size, setSize] = useState('large');
    const [rooms, setRooms] = useState([]);
    // const [roomID, setRoomID] = useState(location.state['roomID']);


    const showModal = () => {
        setIsModalVisible(visible);
    };


    function occupyRoom() {
        navigate('/rooms/occupy', { state: { 'roomCode': code, 'valor': valor, status: 'Ocupado' } })
    }

    function openRoom() {
        if (Object.keys(room).length > 0){
            navigate('/rooms/fine/exceed-time', { state: { 'room': room } })
        }else{
            const data = {
                code: code,
                is_occupy: false,
                time: null,
                status: "Limpeza",
                aditional_time: false,
            }
    
    
            baseurl.post(`v1/Open_Room_by_code`, data).then((resp) => {
                if (resp.status == 200 || resp.status == 201) {
                    setIsModalVisible(false);
                    setVisible(false)
                    sethandleData(true)
                } else {
                }
            })
            // navigate('/rooms/fine/exceed-time', { state: { 'roomCode': code } })
            console.log("aquio", code)
        }
        
    }


    function restaurant() {
        navigate("/rooms/restaurant")
    }

    function maintenance() {
        navigate("/rooms/maintenance")
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setVisible(false)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    useEffect(() => {
        baseurl.get('v1/list_all_rooms').then((response) => setRooms(response.data)).catch((err) => {
        })
    }, [])


    return (
        <>

            <Modal
                title={`Quarto (${code})`}
                visible={visible}
                onOk={handleOk}
                onCancel={() => { setVisible(false); onCancel(false); }}
            >
                <Row gutter={[10, 4]} align="center">
                    {status && <> <Col span={10}>
                        <Button type="primary" onClick={openRoom} size={size} block>Desocupar</Button>
                    </Col></> || <><Col span={10}>
                        <Button type="primary" onClick={occupyRoom} size={size} block>Ocupar</Button>
                    </Col></>}
                    <Col span={10}>
                        <Button type="primary" onClick={restaurant} size={size} block>Vendas</Button>
                    </Col>
                    <Col span={10}>
                        <Button type="primary" onClick={maintenance} size={size} block>Manutenção</Button>
                    </Col>
                </Row>

            </Modal>
        </>
    );
};

export default RoomOptions;
