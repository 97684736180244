import React, { useState } from "react";
import { Typography } from "antd";
import { Row, Col, Card, Spin, Divider } from "antd";
import { baseurl } from "../../components/lib/settings";
import {
  GenericSuccess,
  LoginError,
  LoginSuccess,
  SomeThingError,
} from "../../components/message/generic_message";
import { LoadingOutlined } from "@ant-design/icons";
import Inactivity from "../../components/inactivityTime/InactivityTime";
import { useEffect } from "react";
import CustomTable from "../../components/Table/customTable";
import Customers from "../../components/Table/anotherTable";
import BarChart from "../../components/Chart/BarChart";
import LiquidPlot from "../../components/Chart/LiquidPlot";
import "./reports.css"
import LineChart from "../../components/Chart/LineChart";
import HBarChart from "../../components/Chart/HBarChart";


const gridStyle = {
  width: '25%',
  textAlign: 'center',
}
const { Title, Text } = Typography;
const loadIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;


export default function BasicReports() {
  //   Inactivity();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [employeeReport, setEmployeeReport] = useState([]);
  const [roomReport, setRoomReport] = useState([]);
  const [salesByUser, setSalesByUser] = useState([]);
  const [anualGuestHouseBalanceReport, setAnualGuestHouseBalanceReport] = useState([])

  useEffect(() => {
    setIsLoading(true)
    baseurl.get("v1/employee_report/").then((resp) => {
      setEmployeeReport(resp.data)
    }).catch((error) => {
      setError(true)
    });
    baseurl.get("v1/room_ocupacy_report/").then((resp) => {
      setRoomReport(resp.data)
    }).catch((error) => {
      setError(true)
    });
    baseurl.get("v1/sales_by_user_report/").then((resp) => {
      setSalesByUser(resp.data)
    }).catch((error) => {
      setError(true)
    });
    baseurl.get("v1/get_anual_guesthouse_balance/").then((resp) => {
      setAnualGuestHouseBalanceReport(resp.data)
      setIsLoading(false)

    }).catch((error) => {
      setError((true))
    });
  }, [])

  return (
    <div>
      {error && (
        <>
          <SomeThingError />
        </>
      )}
      {success && (
        <>
          {" "}
          <GenericSuccess msg={"Sucesso"} />{" "}
        </>
      )}

      <div >
        {isLoading &&
          <div className="geraltitleficha">
            <Spin indicator={loadIcon} />
          </div>
          || <>
            <Typography>
              <Title level={4}>
                <div className="geraltitleficha">
                  <div className="">Reports</div>
                </div>
              </Title>
            </Typography>
            <br />
            <Row
              type="flex"
              style={{ alignItems: 'center' }}
              justify="center"
              block
            >
              <Col cs={21} xl={18}>
                {/* <div className="geraltitleficha">
                  <div className="">Visão Geral dos Funcionários</div>
                </div> */}
                <Divider >Visão Geral dos Funcionários</Divider>
                <br />
                <Customers
                  data={employeeReport}
                />
              </Col>

              <Col xs={21} xl={18}>
                {/* <div className="geraltitleficha">
                  <div className="">Nr de Ocupacões por Quarto</div>
                </div> */}
                <br />
                <br />
                <Divider >Nr de Ocupacões por Quarto</Divider>
                <BarChart data={roomReport} />
              </Col>

              <Col xs={21} xl={18}>
                {/* <div className="geraltitleficha">
                  <div className="">Estatística de ganhos Mensais</div>
                </div> */}
                <br />
                <br />

                <Divider >Estatística de ganhos Mensais</Divider>

                <HBarChart data={anualGuestHouseBalanceReport} />
                <br />
                <br />

              </Col>
            </Row>
          </>}
      </div>
    </div>
  );
}
