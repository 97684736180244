import React from "react";
import { Menu, Checkbox, Grid, Spin } from "antd";
import { useEffect } from "react";
import { baseurl } from "../lib/settings";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const LeftMenu = () => {
  const { md } = useBreakpoint();
  const [controlData, setControData] = useState(false);
  const [check, setCheck] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalOccupy, setTotalOccupy] = useState(0);

  useEffect(() => {
    setLoading(true);
    baseurl.get("v1/auth/user/assign_presence_by_user/").then((response) => {
      userPresence(response.data);
      response.data.map((value) => setIsAdmin(value.user.administrator));
    });
    baseurl.get("v1/count_all_rooms_actions").then((resp) => {
      setTotalOccupy(resp.data);
      console.log("total occupy", resp.data);
    });
  }, [controlData]);

  const userPresence = (data) => {
    data.map((item, index) => {
      setCheck(item.is_present);
      setLoading(false);
      setControData(false);
    });
  };

  function AssignPresence(value) {
    setLoading(true);

    var data = {
      is_present: value.target.checked,
    };
    baseurl
      .post("v1/auth/user/assign_presence/", data)
      .then((resp) => {
        resp.data.map((item, index) => {
          setCheck(item.is_present);
        });
        setLoading(false);
        setControData(true);
      })
      .catch((error) => {
        setLoading(false);
        setControData(true);
      });
  }

  function onChange(values) { }
  const loadIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>

      {/* <b>Total atendidos:</b>
      {(totalOccupy === 0 && <>0</>) || <>{totalOccupy.allroomRequests}</>}
      <br /> */}

      <Menu mode={md ? "horizontal" : "inline"}>
        <Menu.Item key="total">
          <b>Total atendidos:</b>&nbsp;{(totalOccupy === 0 && <>0</>) || <>{totalOccupy.allroomRequests}</>}
        </Menu.Item>
        <Menu.Item key="alipay">
          {(loading && (
            <>
              <Spin indicator={loadIcon} />
            </>
          )) || (
              <>
                <Checkbox
                  defaultChecked={check}
                  onChange={onChange}
                  onClick={(e) => AssignPresence(e)}
                >
                  Presente
                </Checkbox>
              </>
            )}
        </Menu.Item>

        {isAdmin && (
          <>
            <Menu.Item key="mail">
              <a href="/rooms/maintenance/client/classification">Avaliações</a>
            </Menu.Item>
            <Menu.Item key="mail">
              <a href="/rooms/client/completed/classification">
                Avaliações lidas
              </a>
            </Menu.Item>
            <Menu.Item key="mail">
              <a href="/rooms/payment/balance">Saldo dos atendentes</a>
            </Menu.Item>
            <Menu.Item key="mail">
              <a href="/rooms/admin/reports">Relatórios</a>
            </Menu.Item>
            {/* <Menu.Item key="mail">
         <a href="/rooms/maintenance/list">Manutenção</a>
       </Menu.Item> */}
          </>
        )}
      </Menu>
    </>
  );
};

export default LeftMenu;

{
  // (isAdmin && <>HOJE: {totalOccupy.allroomRequests}</>) || <>N/A</>;
}
