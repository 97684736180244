import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Input,
    Divider,
    Typography,
    Row,
    Col,
    InputNumber,
    Select
} from "antd";
import { useNavigate, useLocation } from 'react-router-dom';

// import "antd/dist/antd.css";
import DepositDynamicField from "../../components/DynamicField/DepositDynamicField";
import { baseurl } from "../../components/lib/settings";
import { GenericSuccess } from "../../components/message/generic_message";


const { Title } = Typography
const { Option } = Select

const defaultFormItemLayout = {
    labelCol: {
        xs: { span: 6 }
    },
    wrapperCol: {
        xs: { span: 12 }
    }
};


export default function Deposit() {
    const [managers, setManagers] = useState([]);
    const [managerID, setManagerID] = useState()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        baseurl.get('v1/list_all_managers').then((response) => {
            setManagers(response.data)
            console.log("lista dos managers", response.data)
        })
    }, [])

    const [form] = Form.useForm();

    function handleFinish(values) {
        console.log("location", location.state)
        console.log("roomAmount", location.state.roomsAmount)
        const data = {
            totalRoomAmount: location.state.roomsAmount,
            totalDeposit: values.valor,
            manager: managerID,
            expenses: values.fields,
            isDeposit: true,
        }

        baseurl.post(`v1/make_deposit`, data).then((resp) => {
            if (resp.status == 201 || resp.status == 200) {
                GenericSuccess("Depósito efectuado com sucesso.")
                navigate("/rooms/main")
            } else {
            }
        })
    }

    const handleSelect = (value) => {
        managers.map((item, key) => {
            if (value == item.user.user_name) {
                setManagerID(item.user.id)
                console.log("values do value", value)
                console.log("values do id", item.user.id)
            } else {
                console.log("algo correu mal")
            }
        })
    };

    return (
        <div>
            <br />
            <br />
            <Typography>
                <Title align="center" level={5}>
                    Depósito
                </Title>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Saldo: {location.state.roomsAmount}</b>
            </Typography>
            <Row type="flex"
                style={{ alignItems: "center" }}
                justify="center"
                block
            >

                <Col xs={21} xl={18}>
                    <Form form={form}
                        {...defaultFormItemLayout}
                        name="basic"
                        layout="vertical"
                        onFinish={handleFinish}>
                        <Form.Item
                            name="manager"
                            rules={[{ required: true }]}
                            style={{
                                float: "right",
                                fontSize: 18
                            }}
                        >
                            <Select
                                placeholder="Entregar a"
                                style={{
                                    width: 130,
                                }}
                                onChange={handleSelect}
                            >
                                {managers.map((item, key) => {

                                    return <Option value={item.user.user_name}>{item.user.user_name}</Option>
                                })}
                            </Select>

                        </Form.Item>
                        <Form.Item
                            name="valor"
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                style={{
                                    width: 110
                                }}

                                placeholder="Valor" />
                        </Form.Item>

                        <DepositDynamicField />
                        <Form.Item
                        >
                            <Button type="primary" htmlType="submit" block>
                                Depositar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
