import React, { useState, useEffect } from "react";
import LeftMenu from "./left";
import RightMenu from "./right";
import { Drawer, Button } from "antd";
import "./navbar.css";
import logo from "../assets/logo/cumbeza-logo.png";
import Unlocked from "../assets/images/Lockscree/unlocked.png";
import Lock from "../assets/images/Lockscree/lock-svg.svg";
import { useNavigate } from "react-router-dom";
import { Token } from "../lib/token";

export default function Navbar() {
  const [visible, setVisible] = useState(false);
  const [controlToken, setControlToken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setControlToken(true);
    } else {
      setControlToken(false);
    }
  }, []);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const redirect = () => {
    localStorage.removeItem("token");
    if (sessionStorage.getItem("page")) {
      sessionStorage.removeItem("page");
      sessionStorage.setItem("page", "Auth");
    }
    if (sessionStorage.getItem("rooms")) {
      sessionStorage.setItem("page", "Rooms");
    } else {
      sessionStorage.setItem("page", "Auth");
    }
    // navigate("/users/auth");
    window.location.replace("/users/auth");
    // localStorage.removeItem("token")
  };

  return (
    <>
      <nav className="menuBar">
        <div className="logo">
          {(localStorage.getItem("token") && (
            <a href="/rooms/main">
              <img src={logo} alt="" />
            </a>
          )) || (
            <a href="/">
              <img src={logo} alt="" />
            </a>
          )}
        </div>
        <div className="menuCon">
          {(localStorage.getItem("token") && (
            <>
              <div className="centralize">
                <div className="buttombar">
                  <Button className="barsMenu" type="link" onClick={showDrawer}>
                    <span className="barsBtn"></span>
                  </Button>
                </div>
              </div>
              <div className="lockedscreen">
                <a onClick={redirect}>
                  <img src={Lock} />
                </a>
              </div>
            </>
          )) ||
            (sessionStorage.getItem("page") == "Auth" && (
              <div className="centralize">
                <div className="buttombar">
                  <Button className="barsMenu" type="link" onClick={showDrawer}>
                    {/* <img src={Profile} /> */}
                    <span className="barsBtn"></span>
                  </Button>
                </div>
              </div>
            )) || (
              <>
                <div className="barsMenu">
                  <a onClick={redirect}>
                    <img src={Unlocked} />
                  </a>
                </div>
              </>
            )}

          {(localStorage.getItem("token") && (
            <Drawer
              title=""
              placement="right"
              closable={true}
              onClose={onClose}
              visible={visible}
            >
              <LeftMenu />
              {/* <RightMenu /> */}
            </Drawer>
          )) ||
            (sessionStorage.getItem("page") == "Auth" && (
              <div>
                <Drawer
                  title="Guest House"
                  placement="right"
                  closable={true}
                  onClose={onClose}
                  visible={visible}
                  footer={[<>2022</>]}
                >
                  <h5>Quem somos</h5>
                  <h5>Perguntas frequentes</h5>
                  <h5>Contacte-nos</h5>
                </Drawer>{" "}
              </div>
            ))}
        </div>
      </nav>
      <br />
      <br />
      {/* <br /> */}
    </>
  );
}
