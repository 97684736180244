import React, { useState } from 'react'
import {
  Col,
  Upload,
  Row,
  Input,
  Typography,
  Button,
  Form,
  Spin
}
  from "antd"
import ImgCrop from 'antd-img-crop';
import {
  useNavigate
} from "react-router-dom"
import "./maintenance.css"
import { baseurl } from '../../components/lib/settings';
import { HypnosisLoading } from '../../components/loading/hypnosis';
import Inactivity from '../../components/inactivityTime/InactivityTime';

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} é obrigatório!',
    number: '${label} é obrigatório!',
  },
  number: {
    range: '${label} As horas devem estar entre ${min} e ${max}',
  },
};

const { TextArea } = Input;
const { Title } = Typography;


function Maintenance() {
  Inactivity()

  const navigste = useNavigate()
  const [maintenanceImage, setMaintenanceImage] = useState()
  const [maintenanceDetails, setMaintenanceDetails] = useState([]);
  const [comment, setComment] = useState()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)


  function onChange(newFileList) {
    const array = []
    newFileList.fileList.map((img) => {
      array.push(img['originFileObj'])
    })

    const imgs = {
      img: array
    }
    setMaintenanceImage(array);
  }



  function sendMaintenance(value) {
    setLoading(true)
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const data = {
      comment: value.comment,
      image: maintenanceImage,
    }
    console.log("Dados do data", data)

    baseurl.post('v1/create_and_list_maintenance', data, config).then((response) => {
      setMaintenanceDetails(response.data)
      alert("Submetido com sucesso!")
      navigste('/rooms/main')
      setLoading(false)
      // if (resp.status == 201 || resp.status == 200) {
      //   alert("POSTED")
      // }
    }).catch((error) => {
      console.log("algo correu mal", error)
      setLoading(false)
    })
  }

  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://via.placeholder.com/250',
    },])


  const onPreview = async (file) => {
    let src = file.url;
  }

  return (
    <><br /><br />
      <Typography>
        <Title align="center" level={5}>MANUTENÇÃO</Title>
      </Typography>
      <br />
      {loading &&
        <div className='spiner'>
          <HypnosisLoading />
        </div>
        ||
        <>
          <Row
            type="flex"
            style={{ alignItems: 'center' }}
            justify="center"
          >
            <Col xs={21} xl={18} >
              <Form
                form={form}
                name="basic"
                layout='vertical'
                onFinish={sendMaintenance}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name="image"
                  rules={[{ required: true }]}
                >
                  <Upload.Dragger
                    multiple
                    listType='picture'
                    onChange={onChange}
                  >
                    Tirar ou Carregar fotos
                    <br />

                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name='comment'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Comentário" maxLength={200} />
                </Form.Item>
                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit" block>Submeter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row></>
      }
    </>
  )
}

export default Maintenance
