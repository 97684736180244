import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Col,
    Row,
    Divider,
    Checkbox,
    Switch
} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { baseurl } from "../lib/settings";

const CheckboxGroup = Checkbox.Group;

function CleaningModal(props) {
    const location = useLocation();
    const { visible, setVisible, onCancel, code, valor } = props;
    const [isModalVisible, setIsModalVisible] = useState(visible);
    const [size, setSize] = useState('large');
    const [rooms, setRooms] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const plainOptions = ["Ar Condicionado", "Toalha", "Sabonete"];
    const defaultCheckedList = ["Ar Condicionado", "Toalha"];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);


    let navigate = useNavigate();
    const showModal = () => {
        setIsModalVisible(visible);
    };

    const onChange = (list) => {
   
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    function occupyRoom() {
        navigate('/rooms/occupy', { state: { 'roomCode': code, 'valor': valor } })

    }


    // navigate('/rooms/main', { state: { 'roomID': id } })



    function cleanRoom() {
        const data = {
            code: code,
            attributes: checkedList,
            status: "Disponivel",
            is_occupy: false,
            time: null,
            aditional_time: false,
        }
        
        baseurl.post(`v1/cleanRoom`, data).then((resp) => {
            if (resp.status == 200 || resp.status == 201) {
                navigate("/rooms/main")
                setIsModalVisible(false);
                setVisible(false)
            } else {
                //error menssage
            }
        })


    }



    function restaurant() {
        navigate("/rooms/restaurant")
    }

    function maintenance() {
        navigate("/rooms/maintenance")
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setVisible(false)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onToggle = (checked) => {

    };


    useEffect(() => {
        baseurl.get('v1/list_all_rooms_to_clean').then((response) => setRooms(response.data)).catch((err) => {
   
        })
    }, [])


    return (
        <>
                <Modal
                title={`Quarto (${code})`}
                visible={visible}
                onOk={cleanRoom}
                onCancel={() => { setVisible(false); onCancel(false); }}
            >
                <Row gutter={[10, 4]}>
                    <Col >
                        Quarto limpo? &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        Toalhas disponíveis? &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        Sabonete disponível? &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        AC, TV & lâmpadas desligadas? &nbsp; <Switch onChange={onToggle} />

                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default CleaningModal
