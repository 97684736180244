import React, { useState, useEffect } from 'react'
import { baseurl } from '../../components/lib/settings';
import {
    useNavigate,
    useLocation
} from "react-router-dom"
import { GenericSuccess, SomeThingError } from '../../components/message/generic_message';
import { HypnosisLoading } from '../../components/loading/hypnosis';
import {
    Typography,
    Row,
    Col,
    Form,
    Space,
    Radio,
    InputNumber,
    Button
} from "antd"
import Inactivity from '../../components/inactivityTime/InactivityTime';

const { Title, Text } = Typography;


function FinePayment(props) {
    Inactivity()

    const location = useLocation()
    const navigate = useNavigate()
    const { visible, setVisible, onCancel, code, valor, status, room, sethandleData } = props;
    const [isModalVisible, setIsModalVisible] = useState(visible);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [statusmsg, setStatusMsg] = useState("Processando...");
    const [blur, setBlur] = useState("main-form");
    const [value, setValue] = useState(1);
    const [form] = Form.useForm();


    const onChange = (e) => {
        // console.log("clicou em:", e.target.value)
        setValue(e.target.value);
    };

    console.log("DICT", location.state.dict)

    function OpenRoomAfterFine() {
        setIsLoading(true)
        const data = {
            code: location.state.dict.room.code,
            is_occupy: false,
            time: null,
            status: "Limpeza",
            aditional_time: false,
        }
        // console.log("variavel data no momento do POST", data)

        baseurl.post(`v1/Open_Room_by_code`, data).then((resp) => {
            if (resp.status == 200 || resp.status == 201) {
                setIsModalVisible(false);
                // setVisible(false)
                // sethandleData(true)
                setIsLoading(false)
                navigate("/rooms/main")
            } else {
                alert("Algo correu mal.")
            }
        }).catch((err) => {
            console.log("Algo correu mal", err)
        })

    };


    return (
        <div>
            <br />
            <br />
            <br />
            {error && (
                <>
                    <SomeThingError />
                </>
            )}
            {success && (
                <>
                    {" "}
                    <GenericSuccess msg={" Quarto ocupado com sucesso."} />{" "}
                </>
            )}
            {isLoading && (
                <>
                    <div className="overlay" />
                    <div className="content">
                        <HypnosisLoading />
                        <div className="center-notification">
                            <h1 className="main_messages">{statusmsg}</h1>
                        </div>
                    </div>
                </>
            )}

            <div className={blur}>
                <Typography>
                    <Title level={4}>
                        <div className="geraltitleficha">
                            <div className="">Método de Pagamento</div>
                        </div>
                    </Title>
                </Typography>
                <br />
                <Row
                    type="flex"
                    // style={{ alignItems: 'left' }}
                    justify="center"
                    block
                >
                    <Col cs={21} xl={18}>
                        <Form
                            loading={isLoading}
                            form={form}
                            layout="vertical"
                            // onFinish={SendPayment}
                            onFinish={OpenRoomAfterFine}
                        >
                            <Radio.Group onChange={onChange} value={value}>
                                <Space direction="vertical">
                                    <Radio value={1}>Cash</Radio>
                                    <Radio value={2}>Conta móvel</Radio>
                                    <Radio value={3}>POS</Radio>
                                    <Radio value={5}>
                                        M-PESA
                                        {value === 5 ? (
                                            <Form.Item
                                                name="number"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    prefix="+258"
                                                    style={{
                                                        width: 180,
                                                        marginLeft: 10,
                                                    }}
                                                />
                                            </Form.Item>
                                        ) : null}
                                    </Radio>
                                    <Radio value={6}>E-MOLA</Radio>
                                    <Radio value={4}>Outro</Radio>
                                </Space>
                            </Radio.Group>
                            <br />
                            <br />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    Pagar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FinePayment
