import React, { useState, useEffect } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../../components/lib/settings";
import { format } from "date-fns";
import { LoadingOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import {
  Row,
  Form,
  Col,
  Input,
  Button,
  Typography,
  Select,
  Card,
  Checkbox,
  Spin,
  Switch,
  Empty,
} from "antd";
import Bed from "../../components/assets/images/svg/bed.png";
import SadFace from "../../components/assets/images/svg/sad.svg";
import HappyFace from "../../components/assets/images/svg/happyface.svg";
import NormalFace from "../../components/assets/images/svg/normalface.svg";
import "./ListClientAvaliation.css";
import Inactivity from "../../components/inactivityTime/InactivityTime";

function closeRoom() { }

const { Option } = Select;
const { Title } = Typography;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid registration!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} As horas devem estar entre ${min} e ${max}",
  },
};

function ListClientAvaliation() {
  Inactivity()

  const location = useLocation();
  const [roomID, setRoomID] = useState();
  const [roomValor, setRoomValor] = useState();
  const [form] = Form.useForm();
  const [price, setPrice] = useState([]);
  const [comment, setComment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [controlData, setControlData] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    baseurl.get("v1/client_classification").then((response) => {
      setComment(response.data);
      setIsLoading(false);
      setControlData(false);
    });
  }, [controlData]);

  function markAsCompleted(ClientClassificID, adminClassificID) {
    setIsLoading(true);
    var data = {
      client_classification_id: ClientClassificID,
      admin_avaliation: adminClassificID,
      is_completed: true,
    };
    baseurl.post("v1/client_classification", data).then((response) => {
      setIsLoading(false);
      setControlData(true);
    });
  }

  return (
    <div>
      <br />
      <br />
      <br />
      <Typography>
        <Title align="center" level={5}>
          Opinião dos Clientes
        </Title>
      </Typography>
      <Row type="flex" style={{ alignItems: "center" }} justify="center" block>
        <Col xs={21} xl={18}>
          <Form
            form={form}
            id={"roomID"}
            name="basic"
            layout="vertical"
            onFinish={closeRoom}
            validateMessages={validateMessages}
          >
            <br />

            {(comment.length != 0 &&
              comment.map((item, index) => {
                return (
                  <>
                    <Form.Item
                      name="clientavaliation"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Card>
                        <div className="general_container">
                          <div className="client-comment">
                            {item.roomClassification.comment} <br />{" "}
                            <img className="bed" src={Bed} />{" "}
                            {item.roomClassification.room} <b>|</b>{" "}
                            <Moment format="DD-MMM, HH:mm">
                              {item.created_at}
                            </Moment>{" "}
                            <b>|</b> A:{" "}
                            {(item.roomClassification
                              .attendance_classification == 1 && (
                                <>
                                  <img src={SadFace} />
                                </>
                              )) ||
                              (item.roomClassification
                                .attendance_classification == 2 && (
                                  <>
                                    <img src={NormalFace} />
                                  </>
                                )) ||
                              (item.roomClassification
                                .attendance_classification == 3 && (
                                  <>
                                    <img src={HappyFace} />
                                  </>
                                ))}{" "}
                            L:{" "}
                            {(item.roomClassification.hygiene_classification ==
                              1 && (
                                <>
                                  <img src={SadFace} />
                                </>
                              )) ||
                              (item.roomClassification.hygiene_classification ==
                                2 && (
                                  <>
                                    <img src={NormalFace} />
                                  </>
                                )) ||
                              (item.roomClassification.hygiene_classification ==
                                3 && (
                                  <>
                                    <img src={HappyFace} />
                                  </>
                                ))}
                          </div>

                          <div className="client-toggle">
                            <Switch
                              checked={item.is_completed}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              onChange={() =>
                                markAsCompleted(
                                  item.roomClassification.id,
                                  item.id
                                )
                              }
                            />
                          </div>
                        </div>
                      </Card>
                    </Form.Item>
                  </>
                );
              })) || (
                <div className="empy-info">
                  <Empty
                    description={<span>Nenhuma avaliação disponível.</span>}
                  />
                </div>
              )}
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ListClientAvaliation;
