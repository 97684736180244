import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Checkbox,
  Input,
  notification,
  Avatar,
  List,
  Card,
  Form,
  Badge,
} from "antd";
import { useNavigate } from "react-router-dom";
import { baseurl } from "../../components/lib/settings";
import "./UserAuth.css";
import "antd/dist/antd.css";
import { UserOutlined } from "@ant-design/icons";
import foto from "../../components/assets/listusers/employee.jpg";
import {
  LoginError,
  LoginSuccess,
} from "../../components/message/generic_message";
import { Token } from "../../components/lib/token";

function UserAuth() {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState(false);
  const [estadoUsuario, setEstadoUsuario] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { Title, Paragraph, Text, Link } = Typography;
  const [form] = Form.useForm();
  const [selectUser, setSelectUser] = useState(false);
  const [user, setUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [userEmail, setUserEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);
  const openLogin = (user, email) => {
    setUser(user);
    setUserEmail(email);
    setSelectUser(true);
  };

  useEffect(() => {
    localStorage.removeItem("token")
    console.log("removed token")
    baseurl.get("v1/list_all_users/").then((resp) => {
      setUsers(resp.data);
    });
    sessionStorage.setItem("page", "Auth");
  }, []);

  const usuario = localStorage.setItem("estadoUsuario", estadoUsuario);
  const state = sessionStorage.getItem("page");

  function login(values) {
    setIsLoading(true);

    const data = {
      user_name: userEmail,
      password: values.pin,
    };

    localStorage.removeItem("token");
    baseurl
      .post("login", data)
      .then((resp) => {
        if (resp.status == 200) {
          setSucess(true);
        }
        if (resp.data.token) {
          setSucess(true);
          localStorage.setItem("token", resp.data.token);
          sessionStorage.removeItem("page");
          navigate("/rooms/main");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  }

  function showModal() {
    setIsModalVisible(true);
  }

  function handleOk() {
    setIsModalVisible(false);
  }

  function handleCancel() {
    setIsModalVisible();
  }

  return (
    <>
      {error && (
        <>
          <LoginError />
        </>
      )}
      {sucess && (
        <>
          {" "}
          <LoginSuccess />{" "}
        </>
      )}
      <br />
      <div className="title">Usuários</div>
      <div className="geralcards">
        {users.map((item) => {
          return (
            <div className="cardperfil">
              <Card
                style={{ border: "none" }}
                onClick={() => openLogin(item.user.user_name, item.user.email)}
              >
                {(item.is_present && (
                  <>
                    <Badge text="1000MT">
                      <Avatar
                        onClick={showModal}
                        size={{
                          xs: 64,
                          sm: 64,
                          md: 64,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        style={{ backgroundColor: "#87d068" }}
                        icon={<UserOutlined />}
                      />
                    </Badge>
                  </>
                )) || (
                    <>
                      <Avatar
                        onClick={showModal}
                        size={{
                          xs: 64,
                          sm: 64,
                          md: 64,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        icon={<UserOutlined />}
                      />
                    </>
                  )}
              </Card>
              <p className="user_name-user">{item.user.user_name}</p>
            </div>
          );
        })}
      </div>
      {selectUser && (
        <>
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[]}
          >
            <Typography>
              <Title align="center" level={5}>
                {user}
              </Title>
            </Typography>
            <Row type="flex" style={{ alignItems: "center" }} justify="center">
              <Col xs={21} xl={18}>
                <Form
                  form={form}
                  id={"roomID"}
                  name="basic"
                  layout="vertical"
                  onFinish={login}
                >
                  <Form.Item
                    name="pin"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.Password placeholder="Insira o PIN" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      type="primary"
                      htmlType="submit"
                      block
                    >
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
}

export default UserAuth;
