import React, { useState, useEffect } from "react";
import { Card, Col, Row, Modal, Statistic } from 'antd'
import { baseurl } from "../../components/lib/settings";
import moment from 'moment'
import "./cleaning.css"
import CleaningModal from "../../components/Modal/cleaningModal";

import { Space, Spin } from 'antd';
import Inactivity from "../../components/inactivityTime/InactivityTime";



function Cleaning() {
    Inactivity()

    const [isModal, setIsModal] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [roomCode, setRoomCode] = useState();
    const [roomsAmount, setRoomsAmount] = useState([]);
    const [valor, setValor] = useState();
    const [cont, setCount] = useState('00:00:00');
    const { Countdown } = Statistic;



    useEffect(() => {

        baseurl.get('v1/list_all_rooms_to_clean').then((response) => {
            setRooms(response.data)
            response.data.map((item) => {
                setCount(item.time)
            })
        }).catch((err) => {
        })
        baseurl.get('v1/total_money').then((response) => {
            setRoomsAmount(response.data)
        }).catch((err) => {
        })
    }, [])


    function callModal(id, price) {
        setRoomCode(id)
        setValor(price)
        setIsModal(true);
        setVisible(true)
    }

    function onFinish() {
        alert("Tempo Terminado")
    }


    return (
        <div>

            <div className="site-card-wrapper">
                <div className='pricing'>

                    <br />
                    {/* <hr /> */}
                </div>

                {/* starts here */}

                <main>
                    <section class="cards">
                        {rooms.length != 0 && rooms.map((item, key) => {
                            var time = `${item.time}`
                            var hour = time.split(":")[0];
                            var minute = time.split(":")[1];
                            var sec = time.split(":")[2];
                            return (
                                <>
                                    <div class="card" onClick={() => callModal(item.room.code, item.room.price)} >
                                        <div class="card__content">
                                            <p class="card__title text--medium">{item.room.code}</p>
                                            <div class="card__info">
                                                <p class="text--medium"> {item.is_occupy && <><Countdown style={{ color: "white" }} value={moment.utc().local().hour(hour).minute(minute).seconds(sec)} onFinish={onFinish} /></>}</p>
                                                <p class="card__price text--medium">{item.is_occupy && <p className='limpezass'>{item.status}</p> || <p className='disponivell'>{item.status}</p>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }) || <div className="centralisar">
                                <Space >
                                    <Spin size="large" />
                                </Space>
                            </div>
                        }
                    </section>
                </main>
                {isModal && <CleaningModal
                    visible={visible}
                    setVisible={setVisible}
                    code={roomCode}
                    valor={valor}
                />}
            </div>
        </div>
    )
}

export default Cleaning
