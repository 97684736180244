import React from "react";
import {
  Progress,
  Avatar,
  Table,
  Typography,
  Row,
  Col,
} from "antd";

import '../notifications/notification.css';
import user from '../assets/images/png/userProfile.png'
import Card from "antd/lib/card/Card";

const { Title } = Typography;
const columm = [

  {
    title: "Funcionário",
    dataIndex: "name",
    showOnResponse: true,
    showOnDesktop: true

  },
  {
    title: "Q ocupados",
    dataIndex: "ocupation",
    showOnResponse: true,
    showOnDesktop: true
  },
  {
    title: "Total acumulado",
    dataIndex: "amount",
    showOnResponse: true,
    showOnDesktop: true
  },
  {
    title: "Competência",
    dataIndex: "completion",
    showOnResponse: true,
    showOnDesktop: true
  },
];
export default function CustomTable(props) {

  const employeeList = props.data.map((item) => ({
    key: item.id,
    name: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={user} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>{item.employee}</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    ocupation: (
      <>
        <div className="semibold">{item.ocupation}</div>
      </>
    ),
    amount: (
      <>
        <div className="text-sm">{item.billing}</div>

      </>
    ),
    completion: (
      <>
        <div className="ant-progress-project">
          <Progress percent={(15 * 100).toFixed(0)} size="small" />
          <span>
            <div className="text-sm">15</div>
          </span>
        </div>
      </>
    ),
  }));


  return (
    <div>
      <Table
        columns={columm}
        dataSource={employeeList}
        pagination={false}
        className="ant-border-space"
        mobileBreakPoint={768}
      />

    </div>
  );
}

