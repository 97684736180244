import React, { useEffect, useState } from 'react'
import {
    Typography,
    Empty
} from "antd"
import { baseurl } from '../../components/lib/settings';
import Inactivity from '../../components/inactivityTime/InactivityTime';

const { Title } = Typography;

function ListMaintenance() {
    Inactivity()

    const [maintenance, setMaintenance] = useState([]);

    useEffect(() => {
        baseurl.get('v1/create_and_list_maintenance').then((response) => {
            setMaintenance(response.data)
        }).catch((error) => {
            console.log("Algo correu mal", error)
        })
    }, [])

    return (
        <div>
            <br /><br />
            <Typography>
                <Title align="center" level={5}>Ver Manutenção</Title>
            </Typography>
            <br />

            {(maintenance != 0 && maintenance.map((item) => {
                return (
                    <div>{item.comment}{item.image}</div>
                )
            })) || (
                    <div className="empy-info">
                        <Empty
                            description={<span>Nenhuma manutenção no momento....</span>}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default ListMaintenance
