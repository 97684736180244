import React, { useState, useEffect } from 'react'
import { baseurl } from '../lib/settings';
import {
    Button,
    Modal,
    Col,
    Row,
    Divider,
    Checkbox,
    Switch
} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';






function ManutencaoModal(props) {
    const location = useLocation();
    const { visible, setVisible, onCancel, code, valor } = props;
    const [isModalVisible, setIsModalVisible] = useState(visible);
    const [size, setSize] = useState('large');
    const [rooms, setRooms] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const plainOptions = ["Ar Condicionado", "Toalha", "Sabonete"];
    const defaultCheckedList = ["Ar Condicionado", "Toalha"];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    let navigate = useNavigate();
    const showModal = () => {
        setIsModalVisible(visible);
    };

    const onChange = (list) => {
       
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    function occupyRoom() {
        navigate('/rooms/occupy', { state: { 'roomCode': code, 'valor': valor } })

    }




    function restaurant() {
        navigate("/rooms/restaurant")
    }

    function maintenance() {
        navigate("/rooms/maintenance")
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setVisible(false)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onToggle = (checked) => {
      
    };


    useEffect(() => {
        baseurl.get('v1/list_all_rooms_to_clean').then((response) => setRooms(response.data)).catch((err) => {
           
        })
    }, [])




    function cleanRoom() {
        const data = {
            code: code,
            attributes: checkedList,
            status: "Disponivel",
            is_occupy: false,
            time: "00:00:00",
            aditional_time: false,
        }

        baseurl.post(`v1/cleanRoom`, data).then((resp) => {
            if (resp.status == 200 || resp.status == 201) {
                window.location.reload()
                setIsModalVisible(false);
                setVisible(false)
            } else {
                //error menssage
            }
        })


    }



    return (
        <div>

            <Modal
                title={'Manutenção'}
                visible={visible}
                onOk={cleanRoom}
                onCancel={() => { setVisible(false); onCancel(false); }}
            >
                <Row gutter={[10, 4]}>
                    <Col >
                        Problema 1 &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        Problema 2 &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        Problema 3 &nbsp; <Switch onChange={onToggle} />
                        <br /><br />
                        Problema 4 &nbsp; <Switch onChange={onToggle} />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ManutencaoModal
