import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots"

export default function LineChart(props) {
    const data = props.data.map((item) => (
        {
            Date: `${item.month}`,
            scales: item.balance
        }
    ))

    const config = {
        data,
        padding: "auto",
        xField: "Date",
        yField: "scales",
        xAxis: {
            tickCount: 5,
        },
    };

    return <Line {...config} />

} 