import React, { useState } from 'react'
import { useEffect } from 'react';
import { baseurl } from '../../components/lib/settings';
import moment from 'moment'
import { Card, Col, Row, Modal, Statistic } from 'antd'
import { Space, Spin } from 'antd';
import CleaningModal from '../../components/Modal/cleaningModal';
import ManutencaoModal from '../../components/Modal/ManutencaoModal';
import Inactivity from '../../components/inactivityTime/InactivityTime';
function Manutencao() {
    Inactivity()

    const [rooms, setRooms] = useState([]);
    const [roomCode, setRoomCode] = useState();
    const [visible, setVisible] = useState(false);
    const { Countdown } = Statistic;
    const [isModal, setIsModal] = useState(false);
    const [valor, setValor] = useState();


    useEffect(() => {

        baseurl.get('v1/list_all_rooms_to_maintenance').then((response) => {
            setRooms(response.data)
            response.data.map((item) => {
            })
        }).catch((err) => {
        })
    }, [])

    function callModal() {
        // setRoomCode(id)
        // setValor(price)
        setIsModal(true);
        setVisible(true)
    }

    function onFinish() {
        alert("Tempo Terminado")
    }
    return (
        <div>

            <div className="site-card-wrapper">
                <div className='pricing'>

                    <br />
                    {/* <hr /> */}
                </div>

                {/* starts here */}

                <main>
                    <section class="cards">
                        {rooms.length != 0 && rooms.map((item, key) => {
                            var time = `${item.time}`
                            var hour = time.split(":")[0];
                            var minute = time.split(":")[1];
                            var sec = time.split(":")[2];
                            return (
                                <>
                                    <div class="card" onClick={() => callModal()} >
                                        <div class="card__content">
                                            <p class="card__title text--medium">{item.room.code}</p>
                                            <div class="card__info">
                                                <p class="text--medium"> {item.is_occupy && <><Countdown style={{ color: "white" }} value={moment.utc().local().hour(hour).minute(minute).seconds(sec)} onFinish={onFinish} /></>}</p>
                                                <p class="card__price text--medium">{item.is_occupy && <p className='limpezass'>{item.status}</p> || <p className='disponivell'>{item.status}</p>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }) || <div className="centralisar">
                                <Space >
                                    <Spin size="large" />
                                </Space>
                            </div>
                        }
                    </section>
                </main>
                {isModal && <ManutencaoModal
                    visible={visible}
                    setVisible={setVisible}
                // code={roomCode}
                // valor={valor}
                />}
            </div>
        </div>
    )
}

export default Manutencao
