import React from "react";
import { Layout } from "antd";
import "./footer.css";

const { LFooter } = Layout;

function Footer() {
  var date = new Date();

  return (
    <div class="footer">
      <p>Todos direitos reservados © {date.getFullYear()} Speranza.</p>
      <p>Copyright © {date.getFullYear()} Speranza. All rights reserved.</p>
    </div>
  );
}

export default Footer;
