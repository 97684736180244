import axios from "axios";

export const baseurl = axios.create({
  // baseURL: "https://guesthouseapi.atendimento.co.mz/api/",
  // baseURL: "https://api.kjm.atendimento.co.mz/api/",
  // baseURL: "https://api.g1.atendimento.co.mz/api/",
  // baseURL: "https://api.g2.atendimento.co.mz/api/",


  // baseURL: "http://127.0.0.1:8000/api",
  // baseURL: "http://192.168.18.72:8000/api"
  // baseURL: "https://gestaodequartos.speranza.co.mz/api/"
  baseURL: "http://162.0.237.160:4015/api"

});


baseurl.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token != null) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});
