import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Checkbox, Form, Input, notification } from "antd"
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../components/lib/settings';



function Login() {


    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    // useEffect(()=>{
    //     localStorage.removeItem('token')
    // })

    const openNotification = placement => {
        notification.error({
            message: `Invalid Credentials`,
            description:
                'Invalid email or password',
            placement,
        });
    };

    

    const onFinish = (values) => {
        setIsLoading(true)

        const data = {
            user_name: values.email,
            password: values.password,
        }

        localStorage.removeItem('token')

        baseurl.post('login', data).then((resp) => {

            if (resp.data.token) {
                localStorage.setItem('token', resp.data.token)
                baseurl
                    .get("user", {
                        params: {
                            user: resp.data.token,
                        },
                    })
                    .then((resp) => {
                        // alert(JSON.stringify(resp.data));
                        localStorage.setItem("user", JSON.stringify(resp.data.user));
                        if (resp.data.user.permission) {
                            alert("Administrator")
                        } else {
                            alert("Supervisor")
                        }
                    });
                navigate("/rooms/main")
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setError(true);
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
      
    };


    return (

        <div>
            <br />
            <br />
            <Row
                type="flex"
                style={{ alignItems: 'center' }}
                justify="center"
            >
                <Col xs={21} xl={18} >
                    <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            className="username"
                            // label="Username"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor introduza o seu email',
                                },
                            ]}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>

                        <Form.Item
                            // label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor introduza a sua senha',
                                },
                            ]}
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>

                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            {/* <Checkbox block>Remember me</Checkbox> */}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit" block>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>

    )
}

export default Login